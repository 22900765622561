import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import "./App.css";
import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/Login/Login";
import Form from "./pages/Form/Form";
import { useUserContext } from "./hooks/useUserContext";
import Edit from "./pages/Edit/Edit";

function App() {
  const { user, userSimple } = useUserContext();
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/login/user" element={<Login typeUser="user" />}></Route>
          <Route path="/login/ti" element={<Login typeUser="ti" />}></Route>

          <Route
            path="/form"
            element={userSimple ? <Form /> : <Navigate to="/login/user" />}
          ></Route>

          <Route
            path="/dashboard/:search"
            element={user ? <Dashboard /> : <Navigate to="/login/ti" />}
          ></Route>
          
          <Route
            path="/dashboard/edit/:id"
            element={user ? <Edit /> : <Navigate to="/login/ti" />}
          ></Route>

          <Route path="*" element={<Navigate to="/form" />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
