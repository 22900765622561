import { useEffect, useState } from "react";

import Navbar from "../../components/navbar/Navbar";
import styles from "./Dashboard.module.css";

import { useUserContext } from "../../hooks/useUserContext";
import { useUrlContext } from "../../hooks/useUrlContext";

import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate, useParams } from "react-router-dom";

function Dashboard() {
  const { search } = useParams();

  const [chamados, setChamados] = useState([]);
  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState();

  const { user } = useUserContext();
  const { url } = useUrlContext();

  const [page, setPage] = useState(0);
  const [countRows, setCountRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [temporizador, setTemporazidor] = useState(false);

  const navigate = useNavigate();

  async function getChamados() {
    setLoading(true);
    const corpo = {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Token: user.token,
      },
      mode: "cors",
    };

    let response = await fetch(
      url + `/chamados/${search}/${page + 1}/${rowsPerPage}`,
      corpo
    );
    let chamados = await response.json();
    setLoading(false);
    setChamados(chamados);
  }

  useEffect(() => {
    async function getCountChamados() {
      setLoading(true);
      const corpo = {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Token: user.token,
        },
        mode: "cors",
      };

      let response = await fetch(url + `/chamados/${search}`, corpo);

      let data = await response.json();
      setLoading(false);
      setCountRows(data[0].numero_chamados);
    }
    getChamados();
    getCountChamados();
  }, [search, message, user.token, url, page, rowsPerPage]);

  async function handleFinalizarChamado(chamado) {
    chamado.origem = document.getElementById(
      `origem-${chamado.id_chamado}`
    ).value;
    chamado.concluido = document.getElementById(
      `concluido-${chamado.id_chamado}`
    ).value;
    chamado.observacao = document.getElementById(
      `obs-${chamado.id_chamado}`
    ).value;

    if (!chamado.origem)
      return setMessage({
        text: `Favor preencher o campo ORIGEM do chamado ${chamado.id_chamado}.`,
        type: false,
      });
    if (!chamado.concluido)
      return setMessage({
        text: `Favor preencher o campo CONCLUÍDO do chamado ${chamado.id_chamado}.`,
        type: false,
      });

    const body = {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Token: user.token,
      },
      body: JSON.stringify(chamado),
    };

    let response = await fetch(
      url + `/chamados/finalizar/${chamado.id_chamado}`,
      body
    );
    let respChamado = await response.json();

    setMessage({
      text: `O chamado ${respChamado[0].id_chamado} foi fechado com sucesso.`,
      type: true,
    });
  }

  async function handleDelete(idChamado) {
    const body = {
      method: "DELETE",
      headers: {
        Token: user.token,
      },
    };

    let response = await fetch(url + `/chamados/chamado/${idChamado}`, body);

    let data = await response.json();
    console.log(data);

    if (data.message.includes("sucesso"))
      return setMessage({ text: data.message, type: true });
    if (data.message.includes("Erro"))
      return setMessage({ text: data.message, type: false });
  }
  useEffect(() => {
    if (temporizador) {
      const interval = setInterval(() => {
        getChamados();
      }, 30000);

      return () => clearInterval(interval);
    }
  }, [temporizador]);

  function handleEdit(idChamado) {
    navigate("/dashboard/edit/" + idChamado);
  }

  function formatDate(dateNotFormat) {
    const date = new Date(dateNotFormat);
    const dateNew =
      String(date.getDate()).padStart(2, "0") +
      "/" +
      String(date.getMonth() + 1).padStart(2, "0") +
      "/" +
      date.getFullYear();
    return dateNew;
  }

  function formatTime(dateNotFormat) {
    const date = new Date(dateNotFormat);
    const hour = date.getHours().toString().padStart(2, "0");
    const minute = date.getMinutes().toString().padStart(2, "0");
    const formattedTime = `${hour}:${minute}`;

    return formattedTime;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleTemporizador() {
    if (temporizador) setTemporazidor(false)
    if (!temporizador) setTemporazidor(true)
  }

  return (
    <>
      <Navbar handleTemporizador={handleTemporizador}></Navbar>

      <div className={`flex d-colum ${styles.contaneirMessage}`}>
        {message && (
          <div className={message.type ? "message-ok" : "message-not-ok"}>
            {message.text}
          </div>
        )}
        {loading && (
          <div className="cardLoading">
            <div className="cLoader"></div>
          </div>
        )}
        {chamados && !loading && (
          <Paper
            sx={{
              width: "90%",
              overflow: "hidden",
              marginTop: 3,
              marginBottom: 6,
            }}
          >
            <TableContainer>
              <Table className={styles.table} aria-label="simple table">
                <TableHead sx={{ backgroundColor: "#98FB98", fontWeight: 500 }}>
                  <TableRow>
                    <TableCell align="center">ID</TableCell>
                    <TableCell align="center">DATA</TableCell>
                    <TableCell align="center">HORA</TableCell>
                    <TableCell align="center">SETOR</TableCell>
                    <TableCell align="center">SOLICITANTE</TableCell>
                    <TableCell align="center">ATENDIMENTO</TableCell>
                    <TableCell align="center">ORIGEM</TableCell>
                    <TableCell align="center">CONCLUÍDO</TableCell>
                    <TableCell
                      align="center"
                      sx={{ minWidth: 200, width: 300 }}
                    >
                      OBSERVAÇÃO
                    </TableCell>
                    <TableCell align="center" sx={{ minWidth: 200 }}>
                      FINALIZAR
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {chamados.map((chamado) => (
                    <TableRow key={chamado.id_chamado}>
                      <TableCell align="center">{chamado.id_chamado}</TableCell>
                      <TableCell align="center">
                        {formatDate(chamado.data_criacao)}
                      </TableCell>
                      <TableCell align="center">
                        {formatTime(chamado.data_criacao)}
                      </TableCell>
                      <TableCell align="center">{chamado.setor}</TableCell>
                      <TableCell align="center">
                        {chamado.solicitante}
                      </TableCell>
                      <TableCell align="center">
                        {chamado.atendimento}
                      </TableCell>
                      {chamado.origem ? (
                        <TableCell align="center">{chamado.origem}</TableCell>
                      ) : (
                        <TableCell align="center">
                          <select name="" id={`origem-${chamado.id_chamado}`}>
                            <option value=""></option>
                            <option value="1">EQUIPAMENTO</option>
                            <option value="2">SISTEMA</option>
                            <option value="3">EXTERNOS</option>
                            <option value="4">USUÁRIO</option>
                            <option value="0">OUTROS</option>
                          </select>
                        </TableCell>
                      )}
                      {chamado.concluido !== null ? (
                        <TableCell align="center">
                          {chamado.concluido ? <>SIM</> : <>NAO</>}
                        </TableCell>
                      ) : (
                        <TableCell align="center">
                          <select
                            name=""
                            id={`concluido-${chamado.id_chamado}`}
                          >
                            <option value=""></option>
                            <option value="0">NÃO</option>
                            <option value="1">SIM</option>
                          </select>
                        </TableCell>
                      )}
                      {chamado.observacao || chamado.finalizado ? (
                        <TableCell className={styles.observacao} align="center">
                          {chamado.observacao}
                        </TableCell>
                      ) : (
                        <TableCell align="center">
                          <textarea
                            type="text"
                            id={`obs-${chamado.id_chamado}`}
                          />
                        </TableCell>
                      )}
                      {chamado.finalizado ? (
                        // parte finalizada

                        <TableCell align="center">
                          <IconButton
                            aria-label="delete"
                            onClick={() => handleDelete(chamado.id_chamado)}
                          >
                            <DeleteIcon color="error" />
                          </IconButton>
                          <IconButton
                            aria-label="alter"
                            onClick={() => handleEdit(chamado.id_chamado)}
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      ) : (
                        // parte aberta

                        <TableCell align="center">
                          <IconButton
                            aria-label="delete"
                            onClick={() => handleDelete(chamado.id_chamado)}
                          >
                            <DeleteIcon color="error" />
                          </IconButton>
                          <IconButton
                            aria-label="alter"
                            onClick={() => handleEdit(chamado.id_chamado)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            aria-label="completed"
                            onClick={() => handleFinalizarChamado(chamado)}
                          >
                            <CheckCircleIcon color="success" />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={countRows}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ backgroundColor: "#98FB98" }}
              />
            </TableContainer>
          </Paper>
        )}
      </div>
    </>
  );
}

export default Dashboard;
