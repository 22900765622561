import { createContext, useState } from "react";

export const UrlContext = createContext();

export const UrlContextProvider = ({ children }) => {

  const url = "https://api.transcon.contagem.mg.gov.br/chamados"
  
  // const url = "http://localhost:8001";



  return (
    <UrlContext.Provider value={{ url }}>
      {children}
    </UrlContext.Provider>
  );
};
