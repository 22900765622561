import { Link, useNavigate } from "react-router-dom";
import styles from "./Navbar.module.css";
import { useUserContext } from "../../hooks/useUserContext";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const Navbar = ({handleTemporizador}) => {
  const { setUser } = useUserContext();
  const navigate = useNavigate();

  function handleFinalizados() {
    navigate("/dashboard/finalizados");
  }
  function handleaAbertos() {
    navigate("/dashboard/abertos");
  }
  function handleDashboard() {
    navigate("/dashboard/todos");
  }


  function handleSair() {
    localStorage.clear();
    setUser(undefined);
    navigate("/login/ti");
  }

  return (
    <div className={styles.containerNavbar}>
      <div className={styles.menu}>
        <button onClick={handleFinalizados}>Finalizados</button>
        <button onClick={handleaAbertos}>Abertos</button>
        <button onClick={handleDashboard}>Dashboard</button>
      </div>
      <div className={styles.menu}>
        {handleTemporizador && (
          <button onClick={handleTemporizador}>
            <AccessTimeIcon></AccessTimeIcon>
          </button>
        )}

        <button onClick={handleSair}>Sair</button>
      </div>
    </div>
  );
};

export default Navbar;
