import React, { useState } from "react";
import styles from "./Form.module.css";
import Logo from "../../assets/logo.svg";
import { useUrlContext } from "../../hooks/useUrlContext";
import { useUserContext } from "../../hooks/useUserContext";



function Form() {
  const [setor, setSetor] = useState("");
  const [atendimento, setAtendimento] = useState("");
  const [message, setMessage] = useState();
  const { url } = useUrlContext();
  const { userSimple } = useUserContext();
  
  const [solicitante, setSolicitante] = useState(
    handleNameLastName(userSimple.displayName)
  );
  
  function handleNameLastName(name) {
    const arrayName = name.split(" ");
    return arrayName[0] + " " + arrayName.reverse()[0];
  }

  function handleSubmit(e) {
    e.preventDefault();

    const data = {
      setor,
      solicitante,
      atendimento,
      usuario: userSimple.username,
    };

    const body = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Token: userSimple.token,
      },
      body: JSON.stringify(data),
    };

    fetch(url + "/chamados/", body)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // if (!data.token) throw data.message;
        // Faça algo com os dados retornador
        setMessage({ text: "Chamado cadastrado com sucesso.", type: true });
        setAtendimento("");
        setSolicitante("");
      })
      .catch((error) => {
        console.log("error", error);
        setMessage({ text: error.message, type: true });
        setAtendimento("");
        setSetor("");
        setSolicitante("");
      });
  }

  return (
    <div className="flex">
      <section className={styles.containerForm}>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.containerImg}>
            <img src={Logo} alt="" className={styles.logo} />
          </div>

          {message && (
            
              <div className={message.type ? "message-ok" : "message-not-ok"}>
                <p>{message.text}</p>
              </div>
           
          )}
          <h1>Abrir Chamado - Ti</h1>
          <select name="" onChange={(e)=>setSetor(e.target.value)} required>
            <option value="">Setor</option>
            <option value="1">Presidência</option>
            <option value="2">Vice Presidência</option>
            <option value="3">Assessoria de Gestão e Inovação</option>
            <option value="4">Assessoria Jurídica</option>
            <option value="5">Assessoria de Controle Interno</option>
            <option value="6">Assessoria Contábil</option>
            <option value="7">Assessoria</option>
            <option value="8">Diretoria de Engenharia de Tráfego</option>
            <option value="9">Gerência de Atendimento Regional</option>
            <option value="10">Gerência de Monitoramento Eletrônico</option>
            <option value="11">Gerência de Implantação de Sinalização</option>
            <option value="12">
              Gerência de Elaboração de Projetos Viários
            </option>
            <option value="13">
              Diretoria de Operações e Educação para o Trânsito
            </option>
            <option value="14">Gerência de Operações de Trânsito</option>
            <option value="15">Gerência de Educação para a Mobilidade</option>
            <option value="16">Diretoria de Transportes</option>
            <option value="17">
              Gerência de Estudos e Planejamento de Transportes
            </option>
            <option value="18">Gerência de Transportes Especiais</option>
            <option value="19">Gerência do SIM</option>
            <option value="20">
              Gerência de Gestão de Operações de Transporte
            </option>
            <option value="21">Diretoria Administrativa Financeira</option>
            <option value="22">Gerência de Recursos Humanos</option>
            <option value="23">Gerência Administrativa</option>
            <option value="24">Gerência de Licitações e Contratos</option>
            <option value="25">Gerência Orçamentária e Financeira</option>
            <option value="26">Gerência de Planejamento Orçamentário</option>
            <option value="27">
              Diretoria de Planejamento e Pesquisa de Tráfego
            </option>
            <option value="28">Gerência de Planejamento da Mobilidade</option>
            <option value="29">Gerência de Tecnologia da Informação</option>
            <option value="30">Gerência de Licenciamento de Projetos</option>
            <option value="31">
              Gerência de Análise e Processamento de Infrações
            </option>
            <option value="32">Comunicação</option>
            <option value="33">CICC</option>
            <option value="34">Participação Popular</option>
            <option value="0">Outros</option>
            
          </select>
          

          <input
            type="text"
            placeholder="Solicitante"
            value={solicitante}
            onChange={(e) => setSolicitante(e.target.value)}
            required
          />
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            placeholder="Chamado"
            value={atendimento}
            onChange={(e) => setAtendimento(e.target.value)}
            required
          />
          <button type="submit">Enviar</button>
        </form>
      </section>
    </div>
  );
}

export default Form;
