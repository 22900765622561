import React, { useEffect, useState } from "react";
import styles from "./Edit.module.css";
import { useUrlContext } from "../../hooks/useUrlContext";
import { useUserContext } from "../../hooks/useUserContext";
import { Paper } from "@mui/material";
import Navbar from "../../components/navbar/Navbar";
import { useParams } from "react-router-dom";

function Edit() {
  const { url } = useUrlContext();

  const [setor, setSetor] = useState("");
  const [solicitante, setSolicitante] = useState("");
  const [atendimento, setAtendimento] = useState("");
  const [origem, setOrigem] = useState("");
  const [concluido, setConcluido] = useState("");
  const [observacao, setObservacao] = useState("");
  const [finalizado, setFinalizado] = useState("");



  const [message, setMessage] = useState();
  const [loading, setLoading] = useState();
  const [chamado, setChamado] = useState();

  const { user } = useUserContext();
  const { id } = useParams();

  useEffect(() => {
    async function getChamado() {
      setLoading(true);
      const corpo = {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Token: user.token,
        },
        mode: "cors",
      };

      let response = await fetch(url + `/chamados/chamado/${id}`, corpo);
      let respChamado = await response.json();
      setLoading(false);
      setChamado(respChamado[0]);
    }
    getChamado();
  }, []);

  useEffect(() => {
    if (chamado) {
      console.log(chamado);
      setSetor(chamado.setor);
      setAtendimento(chamado.atendimento);
      setSolicitante(chamado.solicitante);
      setOrigem(chamado.origem);
      setConcluido(chamado.concluido);
      setObservacao(chamado.observacao);
      setFinalizado(chamado.finalizado);
    }
  }, [chamado]);



  function handleSubmit(e) {
    e.preventDefault();

    const data = {
      setor,
      solicitante,
      atendimento,
      origem,
      concluido,
      observacao,
      finalizado,
    };

    const body = {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Token: user.token,
      },
      body: JSON.stringify(data),
    };

    fetch(url + `/chamados/chamado/${id}`, body)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.message) throw data;
        // Faça algo com os dados retornador
        setMessage({ text: "Chamado cadastrado com sucesso.", type: true });
      })
      .catch((error) => {
        console.log("error", error);
        setMessage({ text: error.message, type: false });
      });
  }

  return (
    <>
      <Navbar />
      <div className="flex">
        <Paper
          sx={{
            width: "80%",
            overflow: "hidden",
            marginTop: 3,
            marginBottom: 6,
            padding: "24px",
            display: "flex",
            justifyContent: "center",
            borderRadius: "8px",
          }}
        >
          <form className={styles.form} onSubmit={handleSubmit}>
            {message && (
              <div className={message.type ? "message-ok" : "message-not-ok"}>
                <p>{message.text}</p>
              </div>
            )}
            <h1>Editar Chamado - Ti</h1>

            <div className="d-flex d-colum">
              <label htmlFor="">Setor</label>
              <select
                name=""
                onChange={(e) => setSetor(e.target.value)}
                value={setor || ""}
                required
              >
                <option value="">Setor</option>
                <option value="1">Presidência</option>
                <option value="2">Vice Presidência</option>
                <option value="3">Assessoria de Gestão e Inovação</option>
                <option value="4">Assessoria Jurídica</option>
                <option value="5">Assessoria de Controle Interno</option>
                <option value="6">Assessoria Contábil</option>
                <option value="7">Assessoria</option>
                <option value="8">Diretoria de Engenharia de Tráfego</option>
                <option value="9">Gerência de Atendimento Regional</option>
                <option value="10">Gerência de Monitoramento Eletrônico</option>
                <option value="11">
                  Gerência de Implantação de Sinalização
                </option>
                <option value="12">
                  Gerência de Elaboração de Projetos Viários
                </option>
                <option value="13">
                  Diretoria de Operações e Educação para o Trânsito
                </option>
                <option value="14">Gerência de Operações de Trânsito</option>
                <option value="15">
                  Gerência de Educação para a Mobilidade
                </option>
                <option value="16">Diretoria de Transportes</option>
                <option value="17">
                  Gerência de Estudos e Planejamento de Transportes
                </option>
                <option value="18">Gerência de Transportes Especiais</option>
                <option value="19">Gerência do SIM</option>
                <option value="20">
                  Gerência de Gestão de Operações de Transporte
                </option>
                <option value="21">Diretoria Administrativa Financeira</option>
                <option value="22">Gerência de Recursos Humanos</option>
                <option value="23">Gerência Administrativa</option>
                <option value="24">Gerência de Licitações e Contratos</option>
                <option value="25">Gerência Orçamentária e Financeira</option>
                <option value="26">
                  Gerência de Planejamento Orçamentário
                </option>
                <option value="27">
                  Diretoria de Planejamento e Pesquisa de Tráfego
                </option>
                <option value="28">
                  Gerência de Planejamento da Mobilidade
                </option>
                <option value="29">Gerência de Tecnologia da InEditação</option>
                <option value="30">
                  Gerência de Licenciamento de Projetos
                </option>
                <option value="31">
                  Gerência de Análise e Processamento de Infrações
                </option>
                <option value="32">Comunicação</option>
                <option value="33">CICC</option>
                <option value="34">Participação Popular</option>
                <option value="0">Outros</option>
              </select>
            </div>
            <div className="d-flex d-colum">
              <label htmlFor="">Solicitante</label>
              <input
                type="text"
                placeholder="Solicitante"
                onChange={(e) => setSolicitante(e.target.value)}
                value={solicitante || ""}
                required
              />
            </div>
            <div className="d-flex d-colum">
              <label htmlFor="">Atendimento</label>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                onChange={(e) => setAtendimento(e.target.value)}
                value={atendimento || ""}
                required
              />
            </div>
            <div className={`d-flex d-colum ${styles.inputWidth}`}>
              <label htmlFor="">Origem</label>
              <select
                name=""
                onChange={(e) => setOrigem(e.target.value)}
                value={origem || ""}
                required
              >
                <option value=""></option>
                <option value="1">EQUIPAMENTO</option>
                <option value="2">SISTEMA</option>
                <option value="3">EXTERNOS</option>
                <option value="4">USUÁRIO</option>
                <option value="0">OUTROS</option>
              </select>
            </div>
            <div className={`d-flex d-colum ${styles.inputWidth}`}>
              <label htmlFor="">Concluído</label>
              <select
                name=""
                onChange={(e) => setConcluido(e.target.value)}
                value={concluido || ""}
                required
              >
                <option value=""></option>
                <option value="0">NÃO</option>
                <option value="1">SIM</option>
              </select>
            </div>
            <div className="d-flex d-colum">
              <label htmlFor="">Observações</label>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                onChange={(e) => setObservacao(e.target.value)}
                value={observacao || ""}
              />
            </div>
            <div className={`d-flex d-colum ${styles.inputWidth}`}>
              <label htmlFor="">Finalizado</label>
              <select
                name=""
                onChange={(e) => setFinalizado(e.target.value)}
                value={finalizado || ""}
                required
              >
                <option value=""></option>
                <option value="0">NÃO</option>
                <option value="1">SIM</option>
              </select>
            </div>
            <button type="submit">Editar</button>
          </form>
        </Paper>
      </div>
    </>
  );
}

export default Edit;
