import React, { useState } from "react";
import styles from "./Login.module.css";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../hooks/useUserContext";
import { useUrlContext } from "../../hooks/useUrlContext";





const Login = ({ typeUser }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { setUser, setUserSimple } = useUserContext();
  const { url } = useUrlContext();

  const navigate = useNavigate("");

  function handleSubmit(e) {
    e.preventDefault();

    const data = {
      username,
      password,
    };

    const body = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    };

    fetch(url + "/login/" + typeUser, body)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.token) throw data.message;
        // Faça algo com os dados retornador
        const dataUser = {
          username: data.username,
          displayName: data.displayName,
          token: data.token,
        };

        if (typeUser === 'ti') {
          setUser(dataUser);

          localStorage.setItem("user-chamados-ti", JSON.stringify(dataUser));
          navigate(`/dashboard/todos`);
          return
        }
        if (typeUser === 'user') {
          setUserSimple(dataUser);

          localStorage.setItem("user-chamados-simple", JSON.stringify(dataUser));
          navigate(`/form`);
          return
        }

        
      })
      .catch((error) => {
        console.log("error", error);
        setPassword("");
        setUsername("");
      });
  }

  return (
    <div className={styles.containerLogin}>
      <form className={styles.formLogin} onSubmit={handleSubmit}>
        <h1>Chamados - Ti</h1>
        <input
          type="text"
          placeholder="User"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Sig In</button>
      </form>
      <div className={styles.rodape}>{/* <img src={logo} alt="logo" /> */}</div>
    </div>
  );
};

export default Login;
