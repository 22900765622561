import { createContext, useState } from "react";

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {

  

  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user-chamados-ti"))
  );
  const [userSimple, setUserSimple] = useState(
    JSON.parse(localStorage.getItem("user-chamados-simple"))
  );

 


  return (
    <UserContext.Provider value={{ user, setUser, userSimple, setUserSimple }}>
      {children}
    </UserContext.Provider>
  );
};
